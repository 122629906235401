import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"

const Wrapper = styled(Row)`
  max-width: 750px;
  padding: 30px 0 0 0;
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
  text-align: center;
  padding-bottom: 30px; 
  
  @media only screen and (max-width: 767px) {
    font-size: 26px;
  }
`
const HeadingThree = styled.h4`
  ${props => props.theme.main.fonts.headingThree};
  color: #000000;
  font-weight: bold;
`
const HeadingFour = styled.h5`
  ${props => props.theme.main.fonts.headingFour};
  color: #000000;
  font-weight: bold;
  font-size: 16px;
`
const ParagraphTwo = styled.p`
  ${props => props.theme.main.fonts.paragraphTwo};
`

export default () => {
  return (
    <Container fluid>
      <Wrapper>
        <Col>
          <HeadingTwo>Privacy Policy</HeadingTwo>

          <ParagraphTwo>
            This privacy policy applies to Savor Wellness Inc. including the
            Savor app (our “App”) and <a href="https://www.savor.co/" target="_blank">https://www.savor.co</a> (our “Website”)
            owned and operated by Savor Wellness Inc.
          </ParagraphTwo>

          <HeadingThree>1. ACCOUNTABILITY</HeadingThree>

          <ParagraphTwo>
            When you use Savor’s services, including using our App or visiting
            our Website on the internet or via mobile sites (collectively, the
            “Services”), you entrust us with personal information (“Personal
            Data”). This privacy policy (the “Policy”) sets out what Personal
            Data Savor and our affiliates (“Savor”, “we”, “us”, or “our”)
            collect, and how we use it. It also describes the choices available
            to you regarding our use of your Personal Data and how you can
            access and update this information.
          </ParagraphTwo>

          <ParagraphTwo>
            This Policy is currently in effect and was last updated on April 05,
            2019. We reserve the right to unilaterally modify this privacy
            statement at any time, so please review it frequently. If we make
            material changes to this Policy we will notify you prior to making
            the changes on our homepage and other places we deem appropriate so
            that you are aware of what information we collect, how we use it,
            and under what circumstances, if any, we disclose it.
          </ParagraphTwo>

          <HeadingThree>2. IDENTIFYING PURPOSES</HeadingThree>

          <HeadingFour>Personal Data We Collect</HeadingFour>

          <ParagraphTwo>
            When you use our Services, you may be required to provide personal
            information (additional details below). Collection of certain
            Personal Data (particularly your payment details and contact
            information) is required for many of our products and services and
            if you fail to supply such Personal Data, we may be unable to
            deliver you the products and services in full.
          </ParagraphTwo>

          <ParagraphTwo>
            We may collect the following Personal Data:
          </ParagraphTwo>

          <ol>
            <li>
              Personal information. This may include your name, gender, date of
              birth, nationality, your address and contact details and
              photographs, and other images;
            </li>
            <li>
              Use of product and services. Information such as feedback about
              your experiences and your use of our App and/or Website;
            </li>
            <li>
              Personal interests, preferences, and opinions. Information about
              your interests, preferences, and opinions such as products you are
              interested in and the products you have bought;
            </li>
            <li>
              Our interactions with you. A record of any interactions and
              correspondence between us, such as calls made to us, any
              interactions you have with our staff or representatives (whether
              online, on the phone, or in person) and any interactions with us
              or posts that refer to us on social media;
            </li>
            <li>
              Survey information. Your responses to surveys conducted by us or
              on our behalf; and
            </li>
            <li>
              Use of Website, apps and social media platforms. Details of your
              App usage, visits to our Website, your use of social media
              platforms and other information collected through cookies and
              other tracking technology including information that you look at
              (additional details below), the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use and other diagnostic data. We may also collect
              information about you that is publicly available online, including
              your social media profiles.
            </li>
          </ol>

          <ParagraphTwo>
            We do not store or collect your payment details such as credit or
            debit card number and expiry date, account information relating to
            other payment services (such as online or mobile payment services or
            virtual currency) and billing information. Payments are processed
            through third-party payment processors (such as Apple Store in-App
            Payments, and Google Play in-App Payments) whose use of your
            personal information is governed by their privacy policies.
          </ParagraphTwo>

          <HeadingFour>Method of Collection</HeadingFour>

          <ParagraphTwo>
            We will collect Personal Data from you directly to the greatest
            extent possible. However, we may also collect Personal Data from
            other third parties including:
          </ParagraphTwo>

          <ol>
            <li>other persons that interact with us on your behalf;</li>
            <li>
              our service providers and agents who provide customer service;
            </li>
            <li>
              organizations which conduct credit, fraud, and other checks;
            </li>
            <li>
              law enforcement agencies, and other government or regulatory
              bodies; and
            </li>
            <li>
              providers of third-party websites, apps, and social media
              platforms.
            </li>
          </ol>

          <HeadingFour>Tracking Technologies</HeadingFour>

          <ParagraphTwo>
            As is true of most websites, we gather certain information
            automatically and store it in log files. This information may
            include Internet protocol (IP) addresses, browser type, internet
            service provider (ISP), referring/exit pages, operating system,
            date/time stamp, and/or clickstream data. We do not link this
            automatically-collected data to other information we collect about
            you. Some information is also collected automatically every time you
            visit our Website (e.g., cookies and conversion beacons). We and our
            marketing partners, affiliates, or analytics or service providers,
            use technologies such as cookies, beacons, tags, and scripts, to
            analyze trends, administer the Website, tracking users’ movements
            around the Website, and to gather demographic information about our
            user base as a whole. We may receive reports based on the use of
            these technologies by these companies on an individual and
            aggregated basis.
          </ParagraphTwo>

          <HeadingFour>Cookies</HeadingFour>

          <ParagraphTwo>
            This privacy statement covers the use of cookies by our Website only
            and does not cover the use of cookies by any third parties. Our
            Website uses the Google AdWords remarketing service to advertise on
            third party websites (including Google) to previous visitors to our
            site. This means we will continue to show ads to you across the
            Internet, specifically on the Google Content Network (“GCN”). This
            could be in the form of an advertisement on the Google search
            results page or a site in the GCN.
          </ParagraphTwo>

          <ParagraphTwo>
            We respect your privacy and are not collecting any identifiable
            information through the use of Google’s or any other third party
            remarketing system. Any data that is collected will be used in
            accordance with our own privacy policy and Google’s privacy policy.
          </ParagraphTwo>

          <ParagraphTwo>
            Note that your browser settings may allow you to automatically
            transmit a “Do Not Track” signal to websites and online services you
            visit. We currently do not alter our practices when we receive a “Do
            Not Track” signal from a visitor’s browser. To find out more about
            “Do Not Track”, please visit https://allaboutdnt.com for more
            information.
          </ParagraphTwo>

          <ParagraphTwo>
            We may also partner with other third parties from time to time to
            manage our advertising on other sites. Our third-party partner may
            use technologies such as cookies to gather information about your
            activities on this Website and other sites to provide you
            advertising based upon your browsing activities and interests.
          </ParagraphTwo>

          <HeadingFour>Conversion Beacons</HeadingFour>

          <ParagraphTwo>
            We also use Conversion Beacons (small, simple snippet of HTML code)
            inserted in the source of the designated website pages. Conversion
            Beacon technology works by leveraging industry standard
            browser-based cookie technology and simple standard HTML coding.
            Email recipients who receive a Conversion Beacon enabled email
            message will receive a small unique cookie that is stored in their
            browser session. This unique cookie is later used to connect the
            email recipient with the subsequent recipient activity on the client
            website. The purpose of tracking the activity is to better
            understand the email flow of our subscribers and to measure the
            effectiveness of ads. For this reason, our Conversion Beacons are
            tied to personally identifiable information (email address).
          </ParagraphTwo>

          <HeadingThree>3. CONSENT</HeadingThree>

          <HeadingFour>Use of Personal Data</HeadingFour>

          <ParagraphTwo>
            When you use our Services or visit our Website, you provide us with
            your implied consent to use your Personal Data to fulfill your
            request. We may also use your Personal Data for the following
            purposes:
          </ParagraphTwo>

          <ol>
            <li>
              Provide products and services. To provide our Services to you and
              to send you Service-related communications, to deliver of benefits
              and Services associated with your Services, to identify and verify
              your identity in connection with the Services we provide, or to
              provide or facilitate any special arrangements you may have asked
              for;
            </li>
            <li>
              Customer support. For customer support purposes such as responding
              to your inquiries and requests, providing assistance to you in
              relation to the provision of our Services;
            </li>
            <li>
              Marketing purposes. For providing you with certain marketing
              communications (as explained in more detail below);
            </li>
            <li>
              Social interactions. To administer campaigns, contests and
              sweepstakes conducted by us when you choose to participate in
              them, including disclosing the winners of any such contest;
            </li>
            <li>
              Product and service improvement. For the purposes of improving our
              products and services for the benefit of our customers generally,
              including to ensure that our App, Website and web pages (including
              social media pages) function correctly and in accordance with your
              preferences and circumstances;
            </li>
            <li>
              Special discounts. We offer special discounts and promotions from
              time to time. To assess your eligibility and ensure that your
              order is correctly processed we may need to record additional
              information at the time of ordering; and
            </li>
            <li>
              Legal and administrative purposes. For legal and administrative
              purposes such as verifying payment, screening against fraud,
              screening against abusive orders, money laundering and other
              criminal or unlawful activities, accounting and audit purposes,
              developing, maintaining and testing our systems, for claims
              handling and for understanding, exercising, enforcing or
              protecting our legal rights and those of others.
            </li>
          </ol>

          <HeadingFour>Marketing</HeadingFour>

          <ParagraphTwo>
            We may use Personal Data for marketing and promotional purposes,
            including (i) for sending or showing you updates on latest news,
            offers and promotions in connection with our products and services
            (and the products and services of our group companies and third
            parties) (ii) for sending or showing you joint marketing offers
            about our services and packages, contests and sweepstakes, and
            ancillary services; or (iii) for tailoring and tracking your
            interactions with internet banner advertisement and links from third
            party websites to our Website.
          </ParagraphTwo>

          <ParagraphTwo>
            We may also use Personal Data to analyze our customers’ preferences
            and market trends and derive insights, which we may use to tailor
            the types of products and offers that we present to our customers.
            This may involve us combining Personal Data that we hold about your
            use of our services with information that we have collected about
            your web usage. We may also combine information that we have
            collected about you with information that we have collected about
            our other customers in order to derive these insights and establish
            market trends.
          </ParagraphTwo>

          <ParagraphTwo>
            We may communicate marketing and promotions to you online (including
            by email or through your mobile device or via online banner
            advertisement) and, as appropriate and where required, we will ask
            you for your consent, or otherwise provide you with the opportunity
            to choose not to receive marketing, at the time we collect your
            data.
          </ParagraphTwo>

          <ParagraphTwo>
            You have the right to ask us not to process your Personal Data for
            direct marketing purposes. You can exercise your right to prevent
            such processing by indicating that you do not consent to direct
            marketing at the point at which we collect your Personal Data. You
            can also exercise the right at any time after we have collected and
            used your Personal data for direct marketing purposes by following
            the opt-out instructions contained in the relevant communications or
            updating your email subscriptions.
          </ParagraphTwo>

          <ParagraphTwo>
            We will provide an option to unsubscribe or opt out of further
            communication on any direct marketing communication sent to you. You
            may also opt out by contacting us at our Address set out below.
          </ParagraphTwo>

          <ParagraphTwo>
            Please note that if you choose to unsubscribe or opt out of
            marketing communication, we will still send you communications about
            your orders with us and any other services that we provide to you.
            Where you are an account holder, you will also continue to receive
            administrative emails, account and/or order summaries and updates to
            our services.
          </ParagraphTwo>

          <HeadingFour>Our email offers</HeadingFour>

          <ParagraphTwo>
            When you use our Services you are added to our email list that will
            be used to contact you occasionally with offers that we feel will be
            of interest to you. If you do not want us to contact you with
            offers, you may withdraw your consent at any time. If you later
            change your mind it is just as simple to opt back into the email
            offers of your choice. In addition, all emails sent to you by us
            contain simple instructions telling you how to "unsubscribe."
          </ParagraphTwo>

          <HeadingFour>Children’s Privacy</HeadingFour>

          <ParagraphTwo>
            Our Services are not addressed to anyone under the age of majority
            in the jurisdiction where they reside (“Children”). We do not
            knowingly collect Personal Data from Children. If you are a parent
            or guardian and you are aware that your Child has provided us with
            Personal Data, please contact us. If we become aware that we have
            collected Personal Data from Children we will take steps to delete
            that Personal Data from our systems.
          </ParagraphTwo>

          <HeadingThree>4. LIMITING COLLECTION</HeadingThree>

          <ParagraphTwo>
            We only collect Personal Data to the extent necessary to fulfill the
            purposes set out in section 3 above.
          </ParagraphTwo>

          <ParagraphTwo>
            If a third party, including a family member, friend, or work
            colleague seeks to interact with us on your behalf, that party will
            be asked to provide us with the same Personal Data that we would
            normally collect from you directly in order to provide the Services.
            Unless and until we are advised otherwise, we consider that such
            third party has your consent and authorization to provide us with
            your Personal Data, to receive promotional and service emails, and
            to make orders (and changes thereto) on your behalf in accordance
            with this Policy. Please note that the collection, use and
            disclosure of your Personal Data by a third party is subject to your
            dealings with them and any applicable privacy policies and practices
            they may have.
          </ParagraphTwo>

          <ParagraphTwo>
            If a third party who has used our Services on your behalf or who
            otherwise has your full name, contact information and login
            information and contacts us and seeks information related to the
            Services we provide you or wishes to make changes to it, we will
            disclose your order information and allow such third party to make
            changes as we will conclude that you have given them your consent to
            do so. If you do not want an individual to be able to obtain
            information on or make changes to your profile or the manner in
            which Services are provided to you, you should not share your login
            details with them.
          </ParagraphTwo>

          <ParagraphTwo>
            If you provide us with information about other individuals, you must
            tell those individuals and let them know where they can find a copy
            of this Policy.
          </ParagraphTwo>

          <HeadingThree>5. LIMITING USE, DISCLOSURE AND RETENTION</HeadingThree>

          <ParagraphTwo>
            We do not sell your Personal Data to third parties. Other than as
            set out herein, we will not use or disclose your Personal Data for
            purposes other than those for which it was collected without your
            explicit consent or as required by law.
          </ParagraphTwo>

          <ParagraphTwo>
            We use contractual and other means to ensure that your Personal Data
            is afforded protection that meets the requirements of privacy
            legislation whenever one of our third party service providers is
            used to perform services on our behalf, including without
            limitation, services relating to information technology, marketing,
            data processing and storage, research, conducting surveys and
            customer relations. In certain circumstances, we may disclose your
            Personal Data to third parties as follows:
          </ParagraphTwo>

          <ol>
            <li>
              Our service providers. We will permit our third party service
              providers, including agents and contractors and our affiliates, to
              use your Personal Data on our behalf for the purposes set out in
              section
            </li>
            <li>
              Examples of such third parties include operators of our IT
              systems. We may also disclose your Personal Data to third parties
              in order to facilitate any special arrangements that you may have
              requested;
            </li>
            <li>
              Third parties to facilitate Services. In order to facilitate and
              administer the Services provided to you, we may transfer your
              Personal Data to third parties such as other persons who interact
              with us on your behalf;
            </li>
            <li>
              Our third party partners, for marketing purposes. We may disclose
              your Personal Data to third parties so they can provide marketing
              services or conduct marketing or social interaction activities on
              our behalf such as campaigns, contests, sweepstakes, market
              research, customer surveys, and data analytics to help us improve
              and tailor our marketing activities, products and services.
              Subject to us having obtained appropriate consent from you, we may
              also disclose your Personal Data to our third party marketing
              partners in order that they may market their products and services
              to you. In some cases, our third-party service providers are
              located outside Canada. As such, your Personal Data may be
              processed or stored in other countries. In certain circumstances,
              our third party service providers may be required to disclose
              information to government authorities, regulators, courts, or law
              enforcement in those countries. Customers who do not wish to be
              contacted for research, marketing or survey purposes should advise
              us by contacting us at the Address provided below; and
            </li>
            <li>
              Government and regulatory bodies and other individuals, bodies,
              and organizations. We may disclose your Personal Data to
              governments and regulatory authorities and bodies and to other
              individuals, bodies and organizations such as law enforcement
              bodies, legal advisers, organizations which conduct credit, fraud
              and other checks, and other individuals, bodies and organizations
              for the purposes of complying with our legal obligations. We may
              also disclose your Personal Data as required by law when we
              believe in good faith that disclosure is necessary to protect our
              rights, protect your safety or the safety of others, investigate
              fraud, or respond to a government request. If we are involved in a
              merger, acquisition, or sale of all or a portion of our assets,
              you will be notified via email and/or a prominent notice on our
              Website of any change in ownership or uses of your Personal Data,
              as well as any choices you may have regarding your Personal Data,
              or to any other third party with your prior consent to do so. We
              may also disclose your Personal Data to such individuals, bodies,
              and organizations for reasons of safety and security, to enable us
              to provide our products and services to you and otherwise for
              legal and administrative purposes.
            </li>
          </ol>

          <ParagraphTwo>
            As indicated above, there may be instances where we may share your
            Personal Data with third-party companies. If you do not want us to
            share your Personal Data with these third parties, contact us at our
            Address provided below.
          </ParagraphTwo>

          <HeadingFour>Information Retention</HeadingFour>

          <ParagraphTwo>
            We will retain your Personal Data for as long as is necessary for
            the purpose(s) for which it was collected and any other permitted
            related purpose (for example where we are required to retain
            Personal Data for longer than the purpose for which we originally
            collected it in order to comply with certain regulatory
            requirements). We will retain and use your Personal Data as
            necessary to comply with our legal obligations, resolve disputes,
            and enforce our agreements.
          </ParagraphTwo>

          <ParagraphTwo>
            Our retention periods may vary depending on the purposes for which
            your Personal Data was collected. Once your Personal Data is no
            longer needed, it is either irreversibly anonymized (and the
            anonymized Personal Data will be retained) or securely destroyed.
          </ParagraphTwo>

          <HeadingThree>6. ACCURACY</HeadingThree>

          <ParagraphTwo>
            We will take reasonable efforts to ensure your Personal Data is
            accurate, complete and current. However, we will not routinely
            update your Personal Data unless it is necessary to fulfill the
            purposes set out in section 3.
          </ParagraphTwo>

          <ParagraphTwo>
            When you use our Services or create an account with our Website, if
            applicable, you can ensure that the Personal Data we hold is
            accurate and up to date. To help us do this, you may wish to notify
            us of any changes to your Personal Data.
          </ParagraphTwo>

          <HeadingThree>7. SAFEGUARDS</HeadingThree>

          <ParagraphTwo>
            No data transmission over the internet, a website, mobile
            application or via email or other message services can be guaranteed
            to be secure from intrusion. However, we maintain commercially
            reasonable physical, organizational and technological safeguards to
            protect your Personal Data in accordance with the requirements of
            data protection legislation.
          </ParagraphTwo>

          <ParagraphTwo>
            All Personal Data we collect about you is stored on our or our
            subcontractors’ secure servers. We comply with our security policies
            and standards when accessing or using Personal Data and restrict
            access to your Personal Data to those persons who need to use it for
            the purpose(s) for which it was collected. You are responsible for
            keeping any information that we send to you confidential and for
            complying with any other security procedures that we notify you of.
            In particular, where we have given you (or where you have chosen) a
            password which enables you to access certain parts of our Website,
            we ask you not to share that password with anyone.
          </ParagraphTwo>

          <ParagraphTwo>
            Personal Data is encrypted as it travels over the internet.
            Depending on your web browser you may see a secure webpage
            indicator. The use of Secure Socket Layer protocol (SSL) allows all
            Personal Data, such as your payment card number, to be encrypted as
            it travels from your computer to our order system. Once received,
            the information is unencrypted and processed in the same way as a
            payment card transaction placed via the telephone.
          </ParagraphTwo>

          <ParagraphTwo>
            We treat your Personal Data as strictly confidential, and we have
            taken the appropriate technical and organizational security measures
            against loss or unlawful processing of this information. To this
            end, we use several security techniques including secure servers,
            firewalls, and encryption, as well as a physical safeguard of the
            locations where data is stored. If you have any questions about the
            security of your Personal Data, you can contact us at our Address
            set out below.
          </ParagraphTwo>

          <HeadingThree>
            8. OPENNESS, INDIVIDUAL ACCESS AND CHALLENGING COMPLIANCE
          </HeadingThree>

          <ParagraphTwo>
            Should you have any questions regarding this Policy or concerns with
            respect to our compliance with the requirements of applicable
            privacy legislation, you may contact us at the following address
            (our “Address”):
          </ParagraphTwo>

          <ul>
            <li>Savor Wellness Inc.</li>
            <li>Attention: Nick Tchir, Privacy Officer</li>
            <li>Email: <a href="mailto:support@savor.co">support@savor.co</a></li>
          </ul>

          <ParagraphTwo>
            With certain limited exceptions, you have a right to access your
            Personal Data that we hold, including how we have used or are using
            your Personal Data and whether we hold or process any of your
            Personal Data on behalf of a third party. To request this
            information, please contact us in writing at our Address provided
            above. We will endeavour to provide any help that you need to
            prepare a request for access to your Personal Data. Part of this
            process may involve us requesting you to supply enough information
            to enable us to account for the existence, use and disclosure of the
            Personal Data.
          </ParagraphTwo>

          <ParagraphTwo>
            We will respond to your request within a reasonable timeframe and
            will notify you if we are unable to respond to your request within
            thirty (30) days. If we are unable to provide you with your Personal
            Data, we will explain our reasons for refusing access. Exceptions
            may include information that is prohibitively costly to provide,
            information that contains references to other individuals,
            information that cannot be disclosed for legal, security or
            commercial proprietary reasons, and information that is subject to
            solicitor-client or litigation privilege.
          </ParagraphTwo>

          <ParagraphTwo>
            You have the right to raise a complaint about our processing with
            the data protection regulator in your jurisdiction. In Canada, this
            is the Office of the Privacy Commissioner of Canada (“OPC”). The OPC
            can be reached toll-free at (800) 282-1376.
          </ParagraphTwo>
        </Col>
      </Wrapper>
    </Container>
  )
}
