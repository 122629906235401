import React from 'react';
import Layout from 'components/Layout/Layout';
import SEO from 'components/Layout/Seo';
import Privacy from 'components/Privacy/Privacy';

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Privacy />
  </Layout>
)

export default PrivacyPage